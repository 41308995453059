
import { defineComponent } from "vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default defineComponent({
  name: "RangeSlider",
  components: {
    VueSlider,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    min: {
      type: Number,
      default: 0,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    showTooltip:{
      type: Boolean,
      default: false
    },
    format:{
      type: String,
      default: ""
    },
    hasError: {
      type: Object,
      default: () => ({ enabled: false, type: "" }),
    },
    marks:{
      type: Boolean,
      default: false
    },
    interval:{
      type: Number,
      default: 1
    }
  },
  computed: {
    errorMessage() {
      if (this.hasError.enabled && this.hasError.type == 'multiselection') {
        return this.$t('error_range_slider_multiselection');
      } else if (this.hasError.enabled && this.hasError.type == 'ranking') {
        return this.$t('error_range_slider_ranking');
      }
    },
    value: {
      get(): any {
        return this.modelValue;
      },
      set(newValue): any {
        this.$emit("update:modelValue", newValue);
      }
    },
    tooltipFormat(){
      if(this.format != undefined) return '{value}'+this.format
      return '{value}'
    },
    maxValue(): any {
      return this.max;
    },
    minValue(): any {
      return this.min;
    },
  },
  methods: {
    dragEndFinished(){
      this.$emit('drag-end')
    },
    dragEnd(){
      this.$emit("update-finished");
    },
    clicked(){
      setTimeout(() => {
        this.$emit("update-finished");
      }, "100");  
    },
    addStep() {
      const value = Number(this.value) + 1;
      this.value = value;
      this.dragEnd()
    },
    restStep() {
      let value = Number(this.value) - 1;
      if (this.value == 1) value = 0;
      this.value = value;
      this.dragEnd()
    },
  },
  beforeUpdate() {
    if (this.value < this.minValue) this.value = this.minValue;
    if (this.value > this.maxValue) this.value = this.maxValue;
  },
});
